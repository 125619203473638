<template lang="pug">
a.anchor-container(:href='href' @click="scrollToSection(index)")
  a.anchor {{ text }}
  arrow.arrow
</template>

<script setup lang="ts">
import Arrow from '@/assets/svg/anchor-arrow.svg'

interface Props {
  href: string,
  text: string,
  index: number | string,
}

const props = defineProps<Props>()

const scrollToSection = (index) => {
  const element = document.getElementById(`section-${index}`)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
</script>

<style lang="scss" scoped>
.anchor-container {
  position: absolute;
  width: 100vw;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  bottom: get-vw(17);
  cursor: pointer;
  left: 0;
}

.anchor {
  color: #F69674;
  font-size: get-vw(24);
  font-weight: 600;
  line-height: get-vw(28.18);
}

.arrow {
  width: get-vw(52);
  height: get-vw(52);
}

a {
  text-decoration: none;
}

@include tablet {
  .anchor-container {
    bottom: get-vw(15, "md");
    left:  get-vw(-22, "md");
  }

  .anchor {
    color: $base-500;
    font-size: get-vw(18, "md");
    line-height: get-vw(21.13, "md");
  }

  .arrow {
    width: get-vw(40, "md");
    height: get-vw(40, "md");
  }

}

@include mobile {
  .anchor-container {
    display: none;
  }
}
</style>