import { defineStore } from "pinia"
import { useGraphQLQuery } from '@/composables/useApolloClient'

export type WelcomeState = {
  screens: WelcomeScreen[];
  isFetching: boolean;
}

export type WelcomeScreen = {
  title?: string;
  description?: string;
  list?: WelcomeListItem[];
  image?: string;
  link?: string;
  type?: string;
  authorization?: boolean;
  community_or_experts?: CommunityExpert[];
  button_text?: string;
  logos?: string[];
}

export type WelcomeScreenResponse = {
  title?: string;
  description?: string;
  list?: WelcomeListItem[];
  image?: {
    data: {
      attributes: {
        url: string
      }
    }
  }
  link?: string;
  type?: string;
  authorization?: boolean
  community_or_experts?: CommunityExpertResponse[]
  button_text?: string
}

export type WelcomeListItem = {
  description: string
}

export type CommunityExpertResponse = {
  image?: {
    data: {
      attributes: {
        url: string
      }
    }
  }
  description?: string
  link?: string;
  title_name?: string;
}

export type CommunityExpert = {
  image?: string
  description?: string
  link?: string;
  title_name?: string;
}

export type WelcomeResponse = {
  welcome: {
    data: {
      attributes: {
        Screens: WelcomeScreenResponse[]
      }
    }
  }
}

export const useWelcomeStore = defineStore('welcome', {
  state: (): WelcomeState => ({
    screens: [],
    isFetching: false
  }),
  actions: {
    async fetchWelcome() {
      this.isFetching = true

      const { fetch } = useGraphQLQuery<FAQResponse>(gql`
      
      query {
        welcome {
          data {
            attributes {
              Screens {
                title
                description
                list {
                  description
                }
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                link
                type
                authorization
                button_text
                community_or_experts {
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  description
                  link
                  title_name
                }
              }
            }
          }
        }
      }`)

      const data = await fetch()

      if (data && data.welcome && data.welcome.data) {
        const welcome = data.welcome.data?.attributes

        const screens: WelcomeScreen[] = welcome.Screens.map((screen: WelcomeScreenResponse) => {
          return {
            title: screen.title,
            description: screen.description,
            image: screen.image?.data?.attributes?.url,
            link: screen.link,
            type: screen.type,
            button_text: screen.button_text,
            community_or_experts: screen.community_or_experts?.map((item: CommunityExpertResponse) => {
              return {
                title_name: item.title_name,
                image: item.image?.data?.attributes?.url,
                description: item.description,
                link: item.link
              }
            }),
            logos: screen.community_or_experts?.map((item: CommunityExpertResponse) => {
              return {
                image: item.image?.data?.attributes?.url,
              }
            }),
            authorization: screen.authorization,
            list: screen.list?.map((item: WelcomeListItem) => {
              return {
                description: item.description
              }
            }),
          }
        })

        this.screens = screens
        this.isFetching = false
      }
    },
  }
})



