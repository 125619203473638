<template lang="pug">
.dots-navigation
  .dot(v-for="(section, index) in sections" :key="index" :class="{ active: index === currentSection }" @click="handleClick(index)")
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      required: true,
    },
    currentSection: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(index) {
      this.$emit('scrollToSection', index)
    }
  },
}
</script>

<style lang="scss" scoped>
.dots-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: get-vw(12);
  height: get-vw(12);
  border-radius: 50%;
  background: $base-400;
  margin: get-vw(32) 0;
  cursor: pointer;
  transition: background 0.3s;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: get-vw(6) solid transparent;
    outline: get-vw(2) solid none;
  }

  &.active::before {
    border-color: transparent;
    outline: get-vw(2) solid $base-400;
  }
}

@include tablet {

  .dot {
    width: get-vw(6, "md");
    height: get-vw(6, "md");
    margin: get-vw(16, "md") 0;

    &::before {
      border: get-vw(3, "md") solid transparent;
      outline: get-vw(1, "md") solid none;
    }

    &.active::before {
      outline: get-vw(1, "md") solid $base-400;
    }
  }
}

@include mobile {
  .dots-navigation {
    display: none;
  }
}
</style>
